import http from '@/utils/http';
import Api from '@/api/common';
const rootUrl = '/api';

const Cases = {
	/**
	 * 获取案例列表
	 * @returns
	 */
	getDentalList( params ) {
		return http.get( `${rootUrl}/dentalOrderListV2`, {
			params,
			errCode: 'cases.dentalList'
		});
	},
	/**
	 * 数据印模审核 保存接口
	 * @param {object} data
	 * @returns
	 */
	screeningSave( data ) {
		return http.post( `${rootUrl}/dataScreeningSave`, {
			data,
			errCode: 'cases.screeningSave'
		});
	},
	/**
	 * 数据印模审核 提交接口
	 * @param {object} data
	 * @returns
	 */
	screeningCommit( data ) {
		return http.post( `${rootUrl}/dataScreeningCommit`, {
			data,
			errCode: 'cases.screeningCommit'
		});
	},
	/**
	 * 触发转换 提交接口
	 * @param {object} data
	 * @returns
	 */
	projectfileTrigger( data ) {
		return http.post( `${rootUrl}/triggerServiceForProjectFile`, {
			data,
			errCode: 'cases.projectfileTrigger'
		});
	},
	/**
	 * 获取数字印模 info
	 * @param {string} orderID
	 * @returns
	 */
	screeningInfo( params ) {
		return http.get( `${rootUrl}/dataScreeningInfo`, {
			params,
			errCode: 'cases.screeningInfo'
		});
	},
	/**
	 * 订单操作（确认接单等）
	 * @param {object} params
	 * @returns
	 */
	dentalAction( params ) {
		return http.post( `${rootUrl}/dentalOrderActionV2`, {
			data: params,
			errCode: 'cases.dentalAction'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 获取订单信息list（加工日志，主材信息，发货信息等）
	 * @param {object} params
	 * @returns
	 */
	dentalD( params ) {
		return Api.dentalD( params );
	},
	/**
	 * 获取第三方接口调用日志（恒久）
	 * @param {object} params
	 * @returns
	 */
	getThirdPartCallLogs( params ) {
		return http.get( `${rootUrl}/thirdOrderRequestLog`, {
			params,
			errCode: 'cases.getThirdPartCallLogs'
		});
	},
	/**
	 * 新增消息
	 * @param {object} data
	 * @returns
	 */
	addComment( data ) {
		return http.post( `${rootUrl}/orderCommentAdd`, {
			data,
			errCode: 'cases.addComment'
		});
	},
	/**
	 * 触发转换
	 * @param {object} params
	 * @returns
	 */
	// dentalTrigger( ID ) {
	// 	return http.get( `${rootUrl}/triggerServiceV2?attachID=${ID}`, { errCode: 'cases.dentalTrigger' });
	// },
	/**
	 * 下载接口
	 * @param {object} data
	 * @returns
	 */
	dentalDownload( data ) {
		return http.post( `${rootUrl}/dentalDownload`, {
			data,
			errCode: 'cases.dentalDownload'
		});
	},
	/**
	 * 下载私有文件
	 * @param {*} data
	 * @return {status} 需要另外操作
	 */
	// downloadFile( data ) {
	// 	return http.post( `${rootUrl}/downloadOrderV2`, {
	// 		data,
	// 		errCode: 'cases.dentalDownload'
	// 	});
	// },
	/**
	 * 打印 notes 保存
	 * @param {object} data
	 * @returns
	 */
	printUpdate( data ) {
		return http.post( `${rootUrl}/detailOrderUpdatePrint`, {
			data,
			errCode: 'cases.printUpdate'
		});
	},
	/**
	 * 获取数字印模权限
	 * @param {object} data
	 * @returns
	 */
	smPower( data ) {
		return http.post( `${rootUrl}/getFactoryPower`, { data, errCode: 'cases.smPower' });
	},
	/**
	 * 获取Oem Banner Icon
	 * @param {string} ID
	 * @returns
	 */
	oemInfoGetByID( ID ) {
		return Api.oemInfoGetByID({ tid: ID, source: window.app.entryApiName });
	},
	/**
	 * 获取数字印模报告详情（无状态）
	 * @param {object} params
	 * @returns
	 */
	screeningReport( params ) {
		return http.get( `${rootUrl}/dataScreeningReport`, { params });
	},
	/**
	 * 获取订单详情-附件
	 * @param {object} params
	 * @returns
	 */
	orderSubsidiaryAttach( params ) {
		return http.get( `${rootUrl}/orderSubsidiaryAttachment`, { params });
	},
	/**
	 * 转发时 获取技工所列表
	 * @param {object} params
	 * @returns
	 */
	forwardOrderLab( params ) {
		return http.get( `${rootUrl}/dentalOrderLab`, { params });
	},
	/**
	 * 上传设计和方案
	 * @param {object} data
	 * @returns
	 */
	dentalUploaddesign( data ) {
		return http.post( `${rootUrl}/uploadDesignOrderV2`, {
			data,
			errCode: 'cases.dentalUploaddesign'
		});
	},
	/**
	 * 发货信息添加
	 * @param {object} data
	 * @returns
	 */
	dentalLogistics( data ) {
		return http.post( `${rootUrl}/orderLogisticsAdd`, {
			data,
			errCode: 'cases.dentalLogistics'
		});
	},
	/**
	 * 编辑附件
	 * @param {object} data
	 * @returns
	 */
	draftAttachsModify( data ) {
		return http.post( `${rootUrl}/dentalOrderDraftAttachsModify`, {
			data,
			errCode: 'cases.draftAttachsModify'
		});
	},
	/**
	 * 转发
	 * @param {object} data
	 * @returns
	 */
	orderForward( data ) {
		return http.post( `${rootUrl}/dentalOrderForward`, {
			data,
			errCode: 'cases.orderForward'
		});
	},

	/**
	 * 获取可打印文件列表
	 * @param {object} data
	 * @returns
	 */
	getPrintFileList( params ) {
		return http.get( `${rootUrl}/dlpOrderPrintFileList`, {
			params,
			errCode: 'cases.getPrintFileList'
		});
	},
	/**
	 * 打印文件上传
	 * @param {object} data
	 * @returns
	 */
	printFileUpload( data ) {
		return http.post( `${rootUrl}/dlpPrintFileUpload`, {
			data,
			errCode: 'cases.printFileUpload'
		});
	},
	/**
	 * 检查打印机状态
	 * @param {object} data
	 * @returns
	 */
	checkPrinterStatus( params ) {
		return http.get( `${rootUrl}/dlpCheckStatus`, {
			params,
			errCode: 'cases.checkPrinterStatus'
		});
	},
	/**
	 * 下发可打印文件
	 * @param {object} data
	 * @returns
	 */
	sendPrintFile( data ) {
		return http.post( `${rootUrl}/dlpPrintFileSend`, {
			data,
			errCode: 'cases.sendPrintFile'
		});
	},
	/**
	 * 删除打印文件
	 * @param {object} data
	 * @returns
	 */
	 deletePrintFile( data ){
		return http.post( `${rootUrl}/dlpPrintFileDel`, { data, errCode: 'cases.deletePrintFile' });
	},

	/**
	 * 获取特色功能列表
	 * @returns
	 */
	getFeatureList( params ){
		return http.get( `${rootUrl}/featureAppList`, { params });
	},
	/**
	 * 去设计
	 * @param {*} params
	 * @returns
	 */
	toDesign( data ){
		return http.post( `${rootUrl}/featureToDesign`, { data, errCode: 'cases.toDesign' }, { headers: { isCsrf: true } });
	},
	/**
	 * 获取设计文件下载密钥
	 * @param {object} data
	 * @returns
	 */
	generateSecret( data ){
		return http.post( `${rootUrl}/generateDesignAttachDownloadSecret`, { data, errCode: 'cases.generateSecret ' }, { headers: { isCsrf: true } });
	},
	/**
	 * 订单/影像集获取设计文件下载密钥
	 * @param {object} data
	 * @returns
	 */
	generateAttachDownloadSecret( data ){
		return http.post( `${rootUrl}/generateAttachDownloadSecret`, { data, errCode: '' }, { headers: { isCsrf: true } });
	},
	/**
	 * 订单操作（确认接单等）
	 * @param {object} params
	 * @returns
	 */
	 exportCase( params ) {
		return http.get( `${rootUrl}/exportOrderList`, { params, errCode: 'cases.exportCase' });
	},
	/**
	 * 删除设计方案、文件
	 * @param {object} data
	 *
	 */
	deleteOrderDesign( data ){
		return http.post( `${rootUrl}/deleteOrderDesign`, { data, errCode: 'cases.deleteOrderDesign' }, { headers: { isCsrf: true } });
	},
	/**
   * 云端切片功能授权
   * @param {object} data
   *
   */
	slicePower( data ) {
		return http.post( `${rootUrl}/dlpSlicePower`, { data, errCode: 'slice.slicePower' }, { headers: { isCsrf: true } });
	},
	/**
	 * 获取订单下载限制
	 * @param {object} params
	 * @returns
	 */
	getOrderDownloadLimit( params ) {
		return http.get( `${rootUrl}/getOrderDownloadLimit`, { params, errCode: 'cases.exportCase' });
	},
	/**
   * 判断订单是否可下载接口
   * @param {object} data
   *
   */
	limitDownloadOrder( data ) {
		return http.post( `${rootUrl}/limitDownloadOrder`, { data, errCode: 'slice.slicePower' });
	}
};
export default Cases;
