<template>
	<router-view :key="`sub_${$route.path}`"></router-view>
</template>

<script>
export default {
	data() {
		return {};
	}
};
</script>
