import chunk from 'lodash/chunk';
const defaultColorList = [
	// '#749DFF',
	'#5AD8A6',
	'#7585A1',
	'#F8C648',
	'#EC7E66',
	'#85D0ED',
	'#A285D2',
	'#FFAB6A',
	'#5587FF',
	'#D4E1FF',
	'#40CD9C',
	'#C3EEDF',
	'#7585A1',
	'#CBCFDC',
	'#FFA755',
	'#FFF7EF',
	'#F7CBC1',
	'#804815', '#85D0EF', '#c0e7f6', '#5e65eb', '#d6d8fa', '#ffbd7f', '#ffe9d4', '#50a8a9', '#b6dddd', '#088e60', '#ffa8cc', '#ffdcea',
	'#749ffb', '#78deb3', '#a5acbe', '#f7c74a', '#bf752f', '#85d0ee', '#eeeffc', '#ffab6a', '#d6b5c3', '#50a8a9', '#75a0fa',
	'#edf3ff', '#77dcb5', '#e7f9f3', '#bdc3cd', '#cbd0da', '#f8c64a', '#fbe9af', '#ec7e67'
];

export function getColorList( list ) {
	const arr = chunk( list, defaultColorList.length );
	arr.forEach(( one ) => {
		Array.isArray( one ) && one.forEach(( two, index ) => {
			two.color = defaultColorList[ index ];
		});
	});
	return arr.flat();
}

export function getDeviceColor( list, sn ) {
	return list.find(( item ) => item.sn === sn ).color || '';
}
