const getUserInfo = () => {
	const str = localStorage.getItem(( window.app.entryName || 'dental' ) + '-userInfo' );
	if ( str ) {
		try {
			return JSON.parse( str );
		} catch ( error ) {
			return {};
		}
	}
	return {};
};

export default getUserInfo;
