import info from '@/api/info';
import printApi from '@/api/printer';
import institutionSettingApi from '@/api/institutionSetting';
import {
	$checkPerm,
	$getLocalStorage,
	$getNoProjectLocalStorage,
	$setLocalStorage,
	$setNoProjectLocalStorage,
	formatBytes,
	$rdoLang
} from '@/assets/common/common';
import router from '@/router';
import { VueCookieNext } from 'vue-cookie-next';
import { createStore } from 'vuex';
import infoStore from './info';
import slice from './slice';
import createPersistedState from 'vuex-persistedstate';
import { useLang } from '@/hooks/lang';
import { get as _get, merge as _merge } from 'lodash';

const { $t } = useLang( '@shining3d/app' );

const excludes = ['slice/setLoadingState'];
const persistPlugin = createPersistedState({
	// paths: ['slice'], not work
	key: 'SHINING3D-DLP-PRINTER',
	reducer: ( storeState ) => {
		// 清空图片数据
		const layoutList = storeState.slice.layoutList?.map(( item ) => ({ ...item, screenshot: null }));
		const sliceState = storeState.slice;
		return { slice: { ...sliceState, layoutList, loadingState: { isLoading: false, text: '', secondaryText: ' ' }, onlyFileImport: false } };
	},
	filter: ({ type }) => {
		// 只存slice
		if ( /^slice\//.test( type )) {
			return !excludes.includes( type );
		}
		return false;
	}
});

const store = _merge( infoStore, {
	state: {
		i18n: {}, // 语言
		countryList: $getNoProjectLocalStorage( 'countryList' ) || [], // 国家列表
		phoneAreaList: $getNoProjectLocalStorage( 'areaCodeOptions' ) || [], // 国家码列表
		factoryObjs: $getLocalStorage( 'factoryObjs' ) || [], // 所属机构列表（所有状态）
		factoryNodeList: [], // 所属机构的节点列表
		nodeList: [], // 所有节点列表
		sysParams: $getLocalStorage( 'sysParamsNew' ) || [], // OEM信息
		// uiColor: $getLocalStorage( 'uiColor' ) || {}, // OEM配色
		userInfo: $getLocalStorage( 'userInfo' ) || {}, // 用户信息
		permsList: $getLocalStorage( 'perms' ) || {}, // 用户权限点
		global: {}, // ETCD配置（菜单、版本号等）
		isAside: $getLocalStorage( 'isAside' ), // 侧边显示隐藏
		privacyPolicySwitch: false,
		termsOfUseSwitch: false,
		dataMigrateSwitch: false,
		licenseAgreementSwitch: false,
		orgDissolveNoticeSwitch: false,
		factorySelect: $getLocalStorage( 'currentOrganization' ) || {},
		applyCancelOn: '',
		screenItemsObj: {},
		firstUse: $getLocalStorage( 'firstUse' ) || {},
		autoNum: 0,
		appName: '',
		refreshKey: '', // 刷新页面的key值
		isRefreshInActived: false, // 是否刷新缓存页面的请求
		cachedViews: [],
		handoutDialogShow: false, // 控制下发打印机弹窗
		serialNum: '',
		printFileID: '',
		deviceTypeMap: {},
		defaultToothNotation: $getLocalStorage( 'defaultToothNotation' ) || 'FDI',
		hasOtherOrg: false,
		factoryListObj: {},
		openFactoryID: '',
		stroge: '', // 空间容量变化
		printSocket: '',
		routerBeforeReload: null,
		initLocation: JSON.parse( JSON.stringify( location )),
		hasSlicePower: false,
		hasDesignServicePower: false,
		designPreprintList: [], // 设计单待打印列表(供slice使用)
		designPreprintInfo: {
			num: 0,
			checkList: [],
			checkType: ''
		} // 设计单待打印列表信息（自身使用）
	},

	mutations: {
		setScreenItemsObj( state, val ) {
			state.screenItemsObj = val;
		},
		setApplyCancelOn( state, val ) {
			state.applyCancelOn = val;
		},
		setFactorySelect( state, val ) {
			$setLocalStorage( 'currentOrganization', val );
			state.factorySelect = val;
		},
		setNodeList( state, val ) {
			state.nodeList = val;
		},
		setFactoryNodeList( state, val ) {
			state.factoryNodeList = val;
		},
		setTermsOfUse( state, val ) {
			state.termsOfUseSwitch = !state.termsOfUseSwitch;
		},
		setPrivacyPolicy( state, val ) {
			state.privacyPolicySwitch = !state.privacyPolicySwitch;
		},
		setreshkey( state, value ){
			state.refreshKey = value;
		},
		setIsRefreshInActived( state, value ){
			state.isRefreshInActived = value;
		},
		setDataMigrateNotice( state, val ) {
			state.dataMigrateSwitch = !state.dataMigrateSwitch;
		},
		setLicenseAgreement( state, val ) {
			state.licenseAgreementSwitch = !state.licenseAgreementSwitch;
		},
		setOrgDissolveNotice( state, val ) {
			state.orgDissolveNoticeSwitch = !state.orgDissolveNoticeSwitch;
		},
		setI18n( state, val ) {
			state.i18n = val;
		},
		setCountry( state, val ) {
			$setNoProjectLocalStorage( 'countryList', val );
			state.countryList = val;
		},
		setPhone( state, val ) {
			$setNoProjectLocalStorage( 'areaCodeOptions', val );
			state.phoneAreaList = val;
		},
		setSysParams( state, val ) {
			$setLocalStorage( 'sysParamsNew', val );
			// 如果OEM Logo图标存在则修改网页图标
			if ( document.querySelector( 'link[rel="icon"]' ) && _get( val, 'logo' )) {
				document.querySelector( 'link[rel="icon"]' ).href = _get( val, 'logo' );
			}
			// 更新网页标题OEM名称
			const routerName = _get( router, 'currentRoute.value.name' );
			const title = routerName ? _get( state, `i18n.@shining3d/app.title.${routerName}` ) : '';
			const oemTitle = _get( val, 'title' );
			const theme = _get( val, 'theme' );
			if ( theme ){
				document.querySelector( 'body' ).setAttribute( 'class', theme + 'Oem' );
			}
			document.title = title ? title + ( oemTitle ? '-' + oemTitle : '' ) : oemTitle;
			state.sysParams = val;
		},
		// setUiColor( state, val ) {
		// 	// 标题色 TODO
		// 	val.logoColor && document.documentElement.style.setProperty( '--oem-title-color', val.logoColor );
		// 	$setLocalStorage( 'uiColor', val );
		// 	state.uiColor = val;
		// },
		setFactoryObjs( state, val ) {
			// 获取当前选中机构
			let currentOrganization = val.find(( item ) => item.lastState === 'y' );
			// 判断选择机构不存在且为超管则直接赋值选择全部
			if ( !currentOrganization && $checkPerm( 'dental.admin_dental_order' )){
				currentOrganization = { factoryType: 'all', id: 'all', lastState: 'y', name: '全部机构' };
			}
			// 选中机构存储
			this.commit( 'setFactorySelect', currentOrganization || {});
			$setLocalStorage( 'factoryObjs', val );
			state.factoryObjs = val;
		},
		setUserInfo( state, val ) {
			// 标题存储
			if ( val.nickName ) {
				VueCookieNext.setCookie( 'uTitle', val.nickName, { expire: '3d', path: '/' });
			}
			// 案例下载使用，后续全部接入新项目使用userInfo中的参数
			$setLocalStorage( 'defaultDentalOrderReceive', val.defaultDentalOrderReceive || false );
			$setLocalStorage( 'userInfo', val );
			state.userInfo = val;
		},
		setPermsList( state, val ) {
			$setLocalStorage( 'perms', val );
			state.permsList = val;
		},
		setIsAside( state, val ) {
			$setLocalStorage( 'isAside', val );
			state.isAside = val;
		},
		setGlobal( state, val ) {
			state.global = val;
		},
		setFirstUse( state, val ) {
			$setLocalStorage( 'firstUse', val );
			state.firstUse = val;
		},
		addAutoNum( state ) {
			state.autoNum += 1;
		},
		setAppName( state, val ) {
			state.appName = val;
		},
		setCachedViews( state, val ) {
			state.cachedViews = val;
		},
		triggerHandoutDialogShow( state, val ){
			state.handoutDialogShow = val;
		},
		setPrintFileID ( state, val ){
			state.printFileID = val;
		},
		setDeviceTypeMap( state, val ){
			state.deviceTypeMap = val;
		},
		setDefaultToothNotation( state, val ) {
			$setLocalStorage( 'defaultToothNotation', val );
			state.defaultToothNotation = val;
		},
		setHasOtherOrg( state, val ){
			state.hasOtherOrg = val;
		},
		setOpenFactoryID( state, val ){
			state.openFactoryID = val;
		},
		setFactoryListObj( state, val ){
			state.factoryListObj[ val.key ] = val.value;
		},
		setPrintSocket( state, val ){
			state.printSocket = val;
		},
		delFactoryListObj( state, val ){
			const obj = JSON.parse( JSON.stringify( state.factoryListObj ));
			delete obj[ val.key ];
			state.factoryListObj = obj;
		},
		setRouterBeforeReload( state, callback ) {
			state.routerBeforeReload = callback;
		},
		setStroge( state, val ){
			state.stroge = formatBytes( val );
		},
		setSlicePower( state, val ){
			state.hasSlicePower = val;
		},
		setDesignServicePower( state, val ){
			state.hasDesignServicePower = val;
		},
		setDesignPreprintList( state, val = []){
			state.designPreprintList = val;
		},
		setDesignPreprintInfo( state, val = {}){
			state.designPreprintInfo = Object.assign({}, state.designPreprintInfo, val );
		}
	},
	actions: {
		getCancelInfo({ commit }, callback ) {
			info.cancelInfo().then(( res ) => {
				if ( res.status === 'success' ) {
					commit( 'setApplyCancelOn', _get( res, 'result.cancelEffectiveOn' ));
				} else {
					commit( 'setApplyCancelOn', '' );
				}
				if ( callback ) {
					callback();
				}
			});
		},
		getFactoryListObj({ commit, state }, { id, flag = true }) {
			return new Promise(( resolve, reject ) => {
				if ( flag && state.factoryListObj && state.factoryListObj[ id ]) {
					resolve( state.factoryListObj[ id ]);
				} else {
					institutionSettingApi.getInfo({ tid: id }).then(( res ) => {
						if ( res.status === 'success' ) {
							commit( 'setFactoryListObj', { key: id, value: res });
							resolve( res );
						} else {
							reject( res );
						}
					}).
						catch(( err ) => {
							reject( err );
						});
				}
			});
		},
		routerBeforeReload({ state }) {
			return new Promise(( resolve ) => {
				if ( state.routerBeforeReload ) {
					state.routerBeforeReload().then(( status ) => {
						resolve( status );
					}).
						catch(() => {
							resolve( true );
						});
				} else {
					resolve( true );
				}
			});
		},
		// 获取空间容量
		getSpaceStroge({ commit, state }){
			// 避免新注册用户，没机构情况
			if ( state.factorySelect?.id ){
				info.getOrgAttachSpaceSize({ orgID: state.factorySelect?.id }).then(( res ) => {
					if ( res.status === 'success' ) {
						if ( res?.result?.hasUsedSize <= 0 ){
							commit( 'setStroge', 0 );
						} else {
							const strogeSpace = formatBytes( res.result.hasUsedSize );
							commit( 'setStroge', strogeSpace );
						}
					}
				});
			}
		},
		getSlicePower({ commit, state }){
			printApi.slicePower({ orgID: state.factorySelect?.id }).then(( res ) => {
				if ( res.status === 'success' ) {
					commit( 'setSlicePower', res.result?.hasPower );
				}
			});
		}
	},
	modules: { slice },
	plugins: [persistPlugin]
});

export default createStore( store );
