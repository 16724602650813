import { cloneDeep, omit } from 'lodash';
import dayjs from 'dayjs';

import { getMatrixByStr, convertMatrix } from '@/utils';
import $store from './index';

export const generateSliceDownloadLayout = ( layoutList ) => {
	const obj = {};
	layoutList.forEach(( layout ) => {
		const layoutData = {};
		layout.modelList.forEach(( model ) => {
			layoutData[ model.id ] = model.matrix;
		});
		obj[ layout.id ] = layoutData;
	});
	return obj;
};

export const generateFileSnapshoot = () => {
	const alreadyImportedFileList = $store.state.slice.importedFileList;
	const obj = {};
	alreadyImportedFileList.forEach(( item ) => {
		const key = item.sourceDentalId ? 'sourceDentalId' : 'id';
		if ( obj[ item[ key ] ]) {
			obj[ item[ key ] ] += 1;
		} else {
			obj[ item[ key ] ] = 1;
		}
	});
	return obj;
};
const createInitState = ( ignorePropArr ) => {
	const origin = {
		curProgress: '',
		// 刚进入切片时要判断是不是同一个用户&同一个组织，不是的话要重置
		// cacheFlag: {
		//   userID: '',
		//   orgID: ''
		// },
		// 从订单设计文件直接进入
		// onlyFileImport: false,
		// onlyFileData: {
		//   orderID: '',
		//   id: ''
		// },
		// step: 0,
		// isRestore: false,
		userNeedRepair: false,
		batchID: '',
		// transformGuideVisible: true,
		// 导入订单文件弹框中表格的选择项
		checkedOrderMap: {},
		// 是否展示导入订单弹框
		showOrderTable: false,
		// loading状态
		loadingState: {
			isLoading: false,
			text: '',
			secondaryText: ' '
		},
		// 在处理中的文件，检测、修复
		inProcessFileList: [],
		// 模型列表中的所有文件
		importedFileList: [],
		// 模型列表中的被选择的文件
		checkedFileIdList: [],
		// 布局列表
		layoutList: [],
		selectLayout: null,
		appSettings: {},
		orderTableSearchParams: {
			page: 1,
			pageSize: 20,
			pages: 0,
			total: 0,
			printStatus: 'unprinted',
			time: [],
			patientName: '',
			doctorName: '',
			designType: null
		},
		// 多个平台切片时的快照，防止同样的数据多次调用，暂时不需要
		sliceDownloadLayout: {},
		// 文件进入时的快照，用于在文件界面下一步时进行对比，如果有修改，那么要重新布局
		snapshootFileObj: {},
		loadingPercentage: 0,
		uninstallDialogVisible: false,
		slicingDownloaded: false,
		accuwareLastErrCode: ''
	};
	return omit( origin, ignorePropArr );
};
const slice = {
	namespaced: true,
	state: {
		curProgress: '',
		// 刚进入切片时要判断是不是同一个用户&同一个组织，不是的话要重置
		cacheFlag: {
			userID: '',
			orgID: ''
		},
		batchID: '',
		transformGuideVisible: true,
		// 从订单设计文件直接进入
		onlyFileImport: false,
		// 导入订单文件弹框中表格的选择项
		checkedOrderMap: {},
		// 是否展示导入订单弹框
		showOrderTable: false,
		// 是否展示导入设计单弹框
		showDesignOrderTable: false,
		// 数据来源
		dataOrigin: '',
		// 导入设计单文件弹框中表格的选择项
		importDesignOrderList: [],
		step: 0,
		// loading状态
		loadingState: {
			isLoading: false,
			text: '',
			secondaryText: ' '
		},
		// 在处理中的文件，检测、修复
		inProcessFileList: [],
		// 模型列表中的所有文件
		importedFileList: [],
		// 模型列表中的被选择的文件
		checkedFileIdList: [],
		// 布局列表
		layoutList: [],
		selectLayout: null,
		appSettings: {},
		orderTableSearchParams: {
			page: 1,
			pageSize: 20,
			pages: 0,
			total: 0,
			printStatus: 'unprinted',
			time: [],
			patientName: '',
			doctorName: '',
			designType: null
		},
		sliceDownloadLayout: {},
		snapshootFileObj: {},
		isRestore: false,
		userNeedRepair: false,
		onlyFileData: {
			orderID: '',
			id: ''
		},
		loadingPercentage: 0,
		uninstallDialogVisible: false,
		applyTypeOptions: [],
		slicingDownloaded: false,
		accuwareLastErrCode: '',
		domID: '',
		isProgress: false
	},
	mutations: {
		setDomID( state, val ) {
			state.domID = val;
		},
		setIsProgress( state, val ) {
			state.isProgress = val;
		},
		setAccuwareLastErrCode( state, val ) {
			state.accuwareLastErrCode = val;
		},
		setSlicingDownloaded( state, val ) {
			state.slicingDownloaded = val;
		},
		setApplyTypeOptions( state, val = []) {
			state.applyTypeOptions = val;
		},
		setUninstallDialogVisible( state, val ) {
			state.uninstallDialogVisible = val;
		},
		setLoadingPercentage( state, val ) {
			state.loadingPercentage = val;
		},
		setOnlyFileData( state, val ) {
			state.onlyFileData = val;
		},
		setIsRestore( state, val ) {
			state.isRestore = val;
		},
		setUserNeedRepair( state, val ) {
			state.userNeedRepair = val;
		},
		setSnapshootFileObj( state, val ) {
			state.snapshootFileObj = val;
		},
		setSliceDownloadLayout( state, val = {}) {
			state.sliceDownloadLayout = val;
		},
		generatePlantName( state ) {
			const type = state.appSettings.type;
			const curTime = dayjs().format( 'YYYYMMDDHHmmss' );
			const applyLabel = state.applyTypeOptions.find(( item ) => item.value === type )?.label;
			if ( applyLabel ) {
				state.layoutList.forEach(( layout, i ) => {
					if ( !layout.isCustomPlantName ) {
						layout.plantName = `${applyLabel}-${curTime}-${String( i + 1 ).padStart( 2, '0' )}${String( layout.modelList.length ).padStart( 2, '0' )}`;
					}
				});
			}
		},
		setBatchID( state, val ) {
			state.batchID = val;
		},
		setOrderTableSearchParams( state, val ) {
			state.orderTableSearchParams = val;
		},
		setCurProgress( state, name ) {
			state.curProgress = name;
		},
		setCacheFlag( state, info ) {
			state.cacheFlag = {
				userID: info.userID,
				orgID: info.orgID
			};
		},
		resetState( state, ignorePropArr ) {
			// 这里要用函数生成的新的对象，防止在应用过程中，多次reset使用了同一引用导致重置不成功
			const initState = createInitState( ignorePropArr );
			for ( const key in initState ) {
				state[ key ] = initState[ key ];
			}
		},
		setTransformGuideVisible( state, transformGuideVisible ) {
			state.transformGuideVisible = transformGuideVisible;
		},
		setPrintTime( state, { layoutId, printTime }) {
			const curLayout = state.layoutList.find(( item ) => item.id === layoutId );
			if ( curLayout ) {
				curLayout.printTime = printTime;
			}
		},
		setOnlyFileImport( state, onlyFileImport ) {
			state.onlyFileImport = onlyFileImport;
		},
		setLayoutList( state, list = []) {
			state.layoutList = list;
		},
		restoreLayoutModelInfo( state, layoutId ) {
			const curLayout = state.layoutList.find(( item ) => item.id === layoutId );
			if ( curLayout ) {
				curLayout.modelList = cloneDeep( curLayout.backupModelList );
				curLayout.backupModelList = null;
			}
		},
		clearBackupLayoutModelInfo( state, layoutId ) {
			const curLayout = state.layoutList.find(( item ) => item.id === layoutId );
			curLayout && ( curLayout.backupModelList = null );
		},
		clearSupportData( state, { layoutId, modelIdList }) {
			const curLayout = state.layoutList.find(( item ) => item.id === layoutId );
			if ( curLayout ) {
				curLayout.modelList.forEach(( model ) => {
					if ( modelIdList.includes( model.id )) {
						model.supportBasePath = null;
						model.supportGridPath = null;
						model.isNoSupport = true;
					}
				});
			}
		},
		fillEmptySupport( state, { layoutId, supportModelList }) {
			const curLayout = state.layoutList.find(( item ) => item.id === layoutId );
			if ( curLayout ) {
				curLayout.modelList.forEach(( model ) => {
					if ( !model.supportBasePath || !model.supportGridPath ) {
						const curModel = supportModelList.find(( item ) => item.fileInfo.fileID === model.id );
						if ( curModel ) {
							model.supportBasePath = curModel?.support?.base?.cdnUrl;
							model.supportGridPath = curModel?.support?.pillar?.cdnUrl;

							model.liftMatrix = convertMatrix( getMatrixByStr( curModel?.support?.matrix || curModel?.layout?.matrix ));
							model.supportBaseMatrix = convertMatrix( getMatrixByStr( curModel?.support?.base?.matrix ));
							model.supportGridMatrix = convertMatrix( getMatrixByStr( curModel?.support?.pillar?.matrix ));
						}
						model.changeSupport = true;
						model.isNoSupport = false;
					}
				});
			}
		},
		backupLayoutModelInfo( state, layoutId ) {
			const curLayout = state.layoutList.find(( item ) => item.id === layoutId );
			curLayout && ( curLayout.backupModelList = cloneDeep( curLayout.modelList ));
		},
		// 删除layout
		deleteSelectedLayout( state ) {
			if ( state.layoutList.length === 1 ) {
				// 只有一个就不要删除了
				return;
			}
			const newLayoutList = state.layoutList.filter(( item ) => !item.selected );
			if ( newLayoutList.length ) {
				newLayoutList[ 0 ].selected = true;
			}
			state.layoutList = newLayoutList;
		},
		setSelectedLayout( state, val ) {
			state.layoutList.forEach(( item ) => {
				item.selected = item.id === val.id;
			});
		},
		// 当物体操作后，更新矩阵
		updateMatrixByModelStateList( state, list = []) {
			const selectedLayout = state.layoutList.find(( item ) => item.selected );
			if ( selectedLayout ) {
				selectedLayout.modelList.forEach(( model ) => {
					list.forEach(( item ) => {
						if ( model.id === item.id ) {
							model.matrix = item.matrix;
							model.liftMatrix = item.matrix;
							model.supportBaseMatrix = item.supportBaseMatrix;
							model.supportGridMatrix = item.supportGridMatrix;
						}
					});
				});
			}
		},
		setStep( state, val ) {
			state.step = val;
		},
		// 多选框勾选
		setLayoutModelSelectList( state, list = []) {
			const selectedLayout = state.layoutList.find(( item ) => item.selected );
			if ( selectedLayout ) {
				selectedLayout.modelList.forEach(( item ) => {
					item.checked = list.includes( item.id );
				});
			}
		},
		// 删除某布局的模型
		deleteLayoutSelectedModelList( state, idList = []) {
			const selectedLayout = state.layoutList.find(( item ) => item.selected );
			const toRemoveFileIDList = [];
			if ( selectedLayout ) {
				const newList = [];
				selectedLayout.modelList.forEach(( item ) => {
					if ( idList.includes( item.id )) {
						toRemoveFileIDList.push({
							isCopy: item.copyFromID,
							id: item.copyFromID || item.honeycombSrcFileID || item.id
						});
					} else {
						newList.push( item );
					}
				});
				// newList = selectedLayout.modelList.filter(( item ) => !idList.includes( item.id ));
				selectedLayout.modelList = newList;
			}
			const toRemoveLocalFileIDList = [];
			toRemoveFileIDList.forEach(({ id, isCopy }) => {
				let toRemoveImportedFile = null;
				if ( isCopy ) {
					toRemoveImportedFile = state.importedFileList.find(( file ) => file.sourceDentalId === id && !toRemoveLocalFileIDList.includes( file.id ));
				} else {
					toRemoveImportedFile = state.importedFileList.find(( file ) => file.id === id && !toRemoveLocalFileIDList.includes( file.id ));
				}
				toRemoveImportedFile && toRemoveLocalFileIDList.push( toRemoveImportedFile.id );
			});

			const newImportedList = state.importedFileList.filter(( item ) => !toRemoveLocalFileIDList.includes( item.id ));
			state.importedFileList = newImportedList;

			const newCheckList = state.checkedFileIdList.filter(( item ) => !toRemoveLocalFileIDList.includes( item ));
			state.checkedFileIdList = newCheckList;
		},
		// 增加某布局的模型
		addLayoutModelList( state, list = []) {
			const selectedLayout = state.layoutList.find(( item ) => item.selected );
			if ( selectedLayout ) {
				selectedLayout.modelList.push( ...list );
			}
		},
		setLoadingState( state, val ) {
			if ( val && !val.secondaryText ) {
				val.secondaryText = ' ';
			}
			state.loadingState = val;
		},
		setLoadingTextState( state, text ) {
			state.loadingState.text = text;
		},
		setInProcessFileList( state, list = []) {
			state.inProcessFileList = list;
		},
		addCheckedOrders( state, orders = []) {
			orders.forEach(( item ) => {
				state.checkedOrderMap[ item.orderID ] = item;
			});
		},
		resetCheckedOrders( state, val = {}) {
			state.checkedOrderMap = val;
		},
		removeCheckedOrders( state, orders = []) {
			orders.forEach(( item ) => {
				Reflect.deleteProperty( state.checkedOrderMap, item.orderID );
			});
		},
		setDataOrigin( state, val ){
			state.dataOrigin = val;
		},
		setDesignOrders( state, val = []){
			state.importDesignOrderList = val;
		},
		setShowOrderTable( state, val ) {
			state.showOrderTable = Boolean( val );
		},
		setShowDesignOrderTable( state, val ) {
			state.showDesignOrderTable = Boolean( val );
		},
		addImportedFileList( state, list ) {
			state.importedFileList.push( ...list );
		},
		setImportedFileList( state, list ) {
			state.importedFileList = list;
		},
		deleteSelectedFileList( state, idList = []) {
			const newList = state.importedFileList.filter(( item ) => !idList.includes( item.id ));
			state.importedFileList = newList;
		},
		clearSelectedFileList( state ) {
			state.importedFileList = [];
		},
		resetCheckedFileIdList( state, list ) {
			state.checkedFileIdList = list || [];
		},
		addCheckedFileIdList( state, list ) {
			state.checkedFileIdList.push( ...list );
		},
		deleteCheckedFileIdList( state, idList = []) {
			const newList = state.checkedFileIdList.filter(( item ) => !idList.includes( item ));
			state.checkedFileIdList = newList;
		},
		clearCheckedFileIdList( state ) {
			state.checkedFileIdList = [];
		},
		// 保存应用配置信息
		setAppSettings( state, obj ) {
			state.appSettings = obj;
		}
	},
	actions: {
		deleteSelectedFileList({ commit }, idList = []) {
			commit( 'deleteSelectedFileList', idList );
			commit( 'deleteCheckedFileIdList', idList );
		}
	}
	// modules: {},
};
export default slice;
