import scanApi from '@/api/scanOrders';
import caseApi from '@/api/cases';
import patientApi from '@/api/patientManagement';
import store from '@/store';
import { useLang } from '@/hooks/lang';
import {
	$getCdnUrl,
	$ossImageByWidthAndHeight
} from '@/assets/common/common';

const { $t } = useLang( '@shining3d/app' );
export default [
	{
		path: '/u/members',
		name: 'members',
		component: () => import( '@/views/members/index.vue' )
	},
	{
		path: '/u/devices',
		name: 'devices',
		component: () => import( '@/views/devices/index.vue' )
	},
	{
		path: '/u/connections',
		name: 'connections',
		component: () => import( '@/views/connections/index.vue' )
	},
	{
		path: '/u/patientManagement',
		name: 'patientManagement',
		component: () => import( '@/views/patientManagement/index.vue' ),
		meta: { keepAlive: true }
	},
	{
		path: '/u/patientManagement/:id',
		name: 'patientDetail',
		component: () => import( '@/views/patientManagement/detail/index.vue' )
	},
	{
		path: '/u/reportManagement',
		name: 'reportManagement',
		component: () => import( '@/views/reportManagement/index.vue' )
	},
	{
		path: '/u/tickets',
		name: 'tickets',
		component: () => import( '@/views/tickets/index.vue' )
	},
	{
		path: '/u/tickets/:id',
		name: 'ticketDetail',
		component: () => import( '@/views/tickets/detail.vue' )
	},
	{
		path: '/u/dashboards',
		name: 'dashboards',
		component: () => import( '@/views/dashboards/index.vue' )
	},
	{
		path: '/u/cases',
		name: 'cases',
		component: () => import( '@/views/cases/index.vue' ),
		meta: { keepAlive: true }
	},
	{
		path: '/u/case/:id',
		name: 'case',
		component: () => import( '@/views/cases/case/index.vue' ),
		meta: { asidePath: '/u/cases' } // 导航条的菜单高亮订单管理
	},
	{
		path: '/u/scanOrders',
		name: 'scanOrders',
		component: () => import( '@/views/scanOrders/index.vue' ),
		meta: { keepAlive: true }
	},
	{
		path: '/u/scanOrders/:id',
		name: 'scanOrderDetail',
		component: () => import( '@/views/scanOrders/detail/index.vue' )
	},
	{
		path: '/u/cases/createOrde/:id',
		name: 'createOrde',
		component: () => import( '@/views/createOrde/index.vue' ),
		beforeEnter: ( to, from, next ) => {
			const { id } = store.state.factorySelect;
			const { scanID, orderID, ictIDs, classTypes } = to.query;
			const { id: patientID } = to.params;
			let paramsObjlist = {};
			if ( scanID ) {
				scanApi.getScanOrderList({
					orgID: id,
					scanID 
				}).then(( res ) => {
					if ( res.status === 'success' ) {
						to.params.row = JSON.stringify( res.result[ 0 ]);
					}
					next();
				}).
					catch(() => {
						next();
					});
			} else if ( orderID ) {
				caseApi.getDentalList({
					orderID,
					orgID: id
				}).then(( res ) => {
					if ( res.status === 'success' ) {
						to.params.row = JSON.stringify( res.result[ 0 ]);
					}
					next();
				}).
					catch(() => {
						next();
					});
			} else if ( ictIDs ) {
				patientApi.getIctList({
					hospitalID: id,
					patientID,
					medicalRecordID: ictIDs,
					isFull: true
				}).then(( res ) => {
					if ( res.status === 'success' ) {
						const row = [];
						const transformData = ( result ) => {
							const paramsObj = {};
							const labelList = window.app.global?.imageCollectionEnums;
							labelList.forEach(( v ) => {
								paramsObj[ v.key ] = { name: $t( v.name ) };
								if ( v.children ) {
									paramsObj[ v.key ].childObj = {};
									v.children.forEach(( child ) => {
										paramsObj[ v.key ].childObj[ child.key ] = {
											name: $t( child.name ),
											type: child.type
										};
									});
								}
							});
							paramsObjlist = paramsObj;
							result.forEach(( v ) => {
								if ( v.attach && Array.isArray( v.attach )) {
									const content = [];
									const contentIndex = [];
									v.attach.forEach(( child ) => {
										if ( child.classType && paramsObj[ child.classType ]) {
											if (
												paramsObj[ child.classType ].childObj &&
												child.detailType &&
												paramsObj[ child.classType ].childObj[ child.detailType ]
											) {
												child.attachTip =
													paramsObj[ child.classType ].childObj[ child.detailType ].name;
												child.type =
													paramsObj[ child.classType ].childObj[ child.detailType ].type;
											}
											const index = contentIndex.indexOf( child.classType );
											if ( index >= 0 ) {
												content[ index ].children.push( child );
											} else {
												const item = {
													name: child.classType,
													label: paramsObj[ child.classType ].name,
													type: paramsObj[ child.classType ].type,
													children: [child]
												};
												content.push( item );
												contentIndex.push( child.classType );
											}
										}
									});
									v.content = content;
									v.contentIndex = contentIndex[ 0 ] || '';
								}
							});
						};
						const ids = ictIDs.split( ',' );
						const types = classTypes.split( ',' );
						const result = {};
						types.forEach(( type, index ) => {
							result[ type ] = res.result[ type ]?.filter(( data ) => data.id === ids[ index ]) || [];
						});
						if ( result?.oralScan ) {
							const biteList = [];
							result.oralScan?.forEach(( v ) => {
								if ( v.attach ) {
									v.attach = v.attach.filter(( w ) => w.detailType !== 'totalJaw1' && w.detailType !== 'totalJaw2' && w.detailType !== 'totalJaw3' );
								}
							});
							transformData( result.oralScan );
							result.oralScan?.forEach(( data ) => {
								data.oralScanlist = [];
								data.showlist = [];
								data.attach.forEach(( y ) => {
									if (
										y.detailType === 'totalJaw1' ||
										y.detailType === 'totalJaw2' ||
										y.detailType === 'totalJaw3'
									) {
										biteList.unshift({
											url: $getCdnUrl({
												bucket: y.image.bucket,
												dfsID: y.image.dfsID
											}),
											filename: y.attachName,
											detailType: y.detailType,
											viewName:
												paramsObjlist[ y.classType ].childObj[ y.detailType ]?.name
										});
									} else {
										data.oralScanlist.push({
											url: $getCdnUrl({
												bucket: y.image.bucket,
												dfsID: y.image.dfsID
											}),
											detailType: y.detailType,
											filename: y.attachName,
											viewName:
												paramsObjlist[ y.classType ].childObj[ y.detailType ]?.name
										});
									}
								});
								if ( biteList.length > 0 ) {
									biteList.forEach(( d ) => {
										data.oralScanlist.unshift( d );
									});
								}
							});
							result?.oralScan.forEach(( item, index ) => {
								item.attach = result?.oralScan[ index ].attach;
								row.push({
									item,
									id: item.id,
									medicalRecordID: item.id,
									classType: 'oralScan'
								});
							});
						}
						if ( result?.faceScan ) {
							transformData( result.faceScan );
							result.faceScan.forEach(( data ) => {
								data.faceScanlist = [];
								data.showlist = [];
								data.attach.forEach(( y ) => {
									if ( y.detailType !== 'exocadJawMotionFile' ) {
										data.faceScanlist.push({
											url: $getCdnUrl({
												bucket: y.image.bucket,
												dfsID: y.image.dfsID
											}),
											detailType: y.detailType,
											filename: y.attachName,
											viewName:
												paramsObjlist[ y.classType ].childObj[ y.detailType ]?.name
										});
									}
								});
								row.push({
									item: data,
									id: data.id,
									medicalRecordID: data.id,
									classType: 'faceScan'
								});
							});
						}
						if ( result?.intraoralImage ) {
							transformData( result.intraoralImage );
							result.intraoralImage.forEach(( data ) => {
								data.intraoralImagelist = [];
								data.intraoralImagelistCanvas = [];
								data.showlist = [];
								data.attach.forEach(( y ) => {
									data.intraoralImagelist.push({
										url: y.downUrl || $getCdnUrl({
											bucket: y.bucket,
											dfsID: y.dfsID
										}),
										filename: y.attachName,
										viewName:
											paramsObjlist[ y.classType ].childObj[ y.detailType ]?.name
									});
									data.intraoralImagelistCanvas.push({
										url:
											y.downUrl ||
											$ossImageByWidthAndHeight({
												bucket: y.bucket,
												dfsID: y.dfsID,
												w: 320,
												h: 240
											}),
										filename: y.attachName
									});
								});
								row.push({
									item: data,
									id: data.id,
									medicalRecordID: data.id,
									classType: 'intraoralImage'
								});
							});
						}
						if ( result?.xRay ) {
							transformData( result.xRay );
							result.xRay.forEach(( data ) => {
								data.xRaylist = [];
								data.showlist = [];
								data.xRaylistCanvas = [];
								data.attach.forEach(( y ) => {
									data.xRaylist.push({
										url: y.downUrl || $getCdnUrl({
											bucket: y.bucket,
											dfsID: y.dfsID
										}),
										filename: y.attachName,
										viewName:
											paramsObjlist[ y.classType ].childObj[ y.detailType ]?.name
									});
									data.xRaylistCanvas.push({
										url:
											y.downUrl ||
											$ossImageByWidthAndHeight({
												bucket: y.bucket,
												dfsID: y.dfsID,
												w: 320,
												h: 240
											}),
										filename: y.attachName
									});
								});
								row.push({
									item: data,
									id: data.id,
									medicalRecordID: data.id,
									classType: 'xRay'
								});
							});
						}
						if ( result?.faceImage ) {
							transformData( result.faceImage );
							result.faceImage.forEach(( data ) => {
								data.faceImagelist = [];
								data.showlist = [];
								data.faceImagelistCanvas = [];
								data.attach.forEach(( y ) => {
									data.faceImagelist.push({
										url: y.downUrl || $getCdnUrl({
											bucket: y.bucket,
											dfsID: y.dfsID
										}),
										filename: y.attachName,
										viewName:
											paramsObjlist[ y.classType ].childObj[ y.detailType ]?.name
									});
									data.faceImagelistCanvas.push({
										url:
											y.downUrl ||
											$ossImageByWidthAndHeight({
												bucket: y.bucket,
												dfsID: y.dfsID,
												w: 232,
												h: 344
											}),
										filename: y.attachName
									});
								});
								row.push({
									item: data,
									id: data.id,
									medicalRecordID: data.id,
									classType: 'faceImage'
								});
							});
						}
						if ( result?.CBCT ) {
							transformData( result.CBCT );
							result.CBCT.forEach(( data ) => {
								data.cbctlist = [];
								data.showlist = [];
								data.attach.forEach(( y ) => {
									data.cbctlist.push({
										url: y.downUrl || $getCdnUrl({
											bucket: y.bucket,
											dfsID: y.dfsID
										}),
										filename: y.attachName,
										viewName:
											paramsObjlist[ y.classType ].childObj[ y.detailType ]?.name
									});
								});
								row.push({
									item: data,
									id: data.id,
									medicalRecordID: data.id,
									classType: 'CBCT'
								});
							});
						}
						if ( result?.other ) {
							result.other.forEach(( data ) => {
								row.push({
									item: data,
									id: data.id,
									medicalRecordID: data.id,
									classType: 'other'
								});
							});
						}
						to.params.row = JSON.stringify( row );
					}
					next();
				}).
					catch(() => {
						next();
					});
			} else {
				next();
			}
		}
	},
	{
		path: '/u/tooth',
		name: 'tooth',
		component: () => import( '@/views/components/tooth.vue' )
	},
	{
		path: '/u/institution-setting/:id/:target',
		name: 'institutionSetting',
		component: () => import( /* webpackChunkName: 'institutionsetting' */ '@/views/institutionSetting/index.vue' )
	},
	{
		path: '/a/institutions',
		name: 'institutions',
		component: () => import( '@/views/institutions/index.vue' )
	},
	{
		path: '/u/printer',
		name: 'printer',
		redirect: '/u/printer/slice',
		component: () => import( '@/views/printer/layout.vue' ),
		children: [
			{
				path: '/u/printer/slice',
				name: 'PrinterSlice',
				component: () => import( '@/views/printer/slice/index.vue' )
			},
			{
				path: '/u/printer/list',
				name: 'PrinterList',
				component: () => import( '@/views/printer/list/index.vue' )
			},
			{
				path: '/u/printer/queue',
				name: 'PrinterQueue',
				component: () => import( '@/views/printer/queue/index.vue' )
			},
			{
				path: '/u/printer/BI',
				name: 'PrinterBI',
				component: () => import( '@/views/printer/Bi/index.vue' )
			}
		]
	}
];
