import { get } from 'lodash';
// import { useStore } from 'vuex';

// const $store = useStore();
const getOrgID = () => {
	const str = localStorage.getItem(( window.app.entryName || 'dental' ) + '-currentOrganization' );
	return str ? get( JSON.parse( str ), 'id', '' ) : '';
	// const str = $store?.state.setFactorySelect.id;
	// console.log( '机构用户信息999', str );
	// return str;
};

export default getOrgID;
