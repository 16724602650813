import { get } from 'lodash';
import { VueCookieNext } from 'vue-cookie-next'; 
const h5RouterMap = {
	case: '/pagesCases/pages/cases/details?id={params.id}&from=cases',
	cases: '/pages/cases/index',
	login: '/pagesLogin/index',
	info: '/pages/mine/index'
};

const getH5Url = ( name, obj ) => {
	if ( name && h5RouterMap[ name ]) {
		const url = h5RouterMap[ name ];
		const newUrl = url.replace( /\{([(\w)|(/.)|/-|/_]+)\}/g, ( match, key ) => get( obj, key ) || match );
		return window.app.global?.h5Domain + newUrl;
	} else {
		return window.app.global?.h5Domain + '/pages/home/404';
	}
};

const browser = {
	versions: ( function() {
		const u = navigator.userAgent;
		return {
			trident: u.indexOf( 'Trident' ) > -1, // IE内核 
			presto: u.indexOf( 'Presto' ) > -1, // opera内核 
			webKit: u.indexOf( 'AppleWebKit' ) > -1, // 苹果、谷歌内核 
			gecko: u.indexOf( 'Gecko' ) > -1 && u.indexOf( 'KHTML' ) === -1, // 火狐内核 
			mobile: Boolean( u.match( /AppleWebKit.*Mobile.*/ )), // 是否为移动终端 
			ios: Boolean( u.match( /\(i[^;]+;( U;)? CPU.+Mac OS X/ )), // ios终端 
			android: u.indexOf( 'Android' ) > -1 || u.indexOf( 'Linux' ) > -1, // android终端或者uc浏览器 
			iPhone: u.indexOf( 'iPhone' ) > -1, // 是否为iPhone或者QQHD浏览器 
			iPad: u.indexOf( 'iPad' ) > -1, // 是否iPad 
			webApp: u.indexOf( 'Safari' ) === -1 // 是否web应该程序，没有头部与底部 
		};
	})(),
	language: ( navigator.browserLanguage || navigator.language ).toLowerCase() 
};

const notToH5Routes = ['reportNew', 'report', 'dataAudit'];

const mobileToH5 = ( to ) => {
	if (( browser.versions.mobile || browser.versions.iPhone ) && !browser.versions.iPad ) {
		if ( to.name && !notToH5Routes.includes( to.name )) {
			// 判断oem是否是index，如果是index再转跳
			const name = to.name;
			if ( name.indexOf( 'oemDomainPage' ) === 0 && to.params?.oemDomain === 'index' ) {
				window.location.replace( getH5Url( 'login', to ));
			} else if ( name.indexOf( 'oemDomainPage' ) !== 0 && VueCookieNext.getCookie( 'oemURL' ) === 'index' ) {
				if ( to.path?.includes( '/inf' )) {
					window.location.replace( getH5Url( 'info', to ));
				} else {
					window.location.replace( getH5Url( to.name, to ));
				}
			} 
			// window.location.replace( getH5Url( to.name, to ));
		}
	}
};

export { 
	mobileToH5,
	browser
};
