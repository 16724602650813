import http from '@/utils/http';
const rootUrl = '/api';

const commonApi = {
	/**
	 * 获取ipc
	 * @returns
	 */
	getIpc() {
		return http.get( `${rootUrl}/ipCountry`, { errCode: 'commonApi.getIpc' });
	},
	/**
	 * 获取ipca
	 * @returns
	 */
	getIpca() {
		return http.get( `${rootUrl}/ipAreaCountryV2`, { errCode: 'commonApi.getIpc' });
	},
	/**
	 * 获取机构的节点列表
	 * @returns
	 */
	getNodes( params ) {
		return http.get( `${rootUrl}/orgDataDistribution`, { params, errCode: 'commonApi.getNodes' });
	},
	/**
	 * 获取更新日志
	 * @returns
	 */
	getUpdateLog( params ) {
		return http.get( `${rootUrl}/fetchDentalPushList`, { params, errCode: 'commonApi.getUpdateLog' });
	},
	/**
	 * 获取公告
	 * @returns
	 */
	getAnnouncement( params ) {
		return http.get( `${rootUrl}/fetchAnnouncePushList`, { params, errCode: 'commonApi.getAnnouncement' });
	},
	/**
	 * 获取更新日志详情
	 * @returns
	 */
	getUpdateLogDetail( params ) {
		return http.get( `${rootUrl}/getAnnouncePushInfo`, { params, errCode: 'commonApi.getUpdateLogDetail' });
	},
	/**
	 * 获取更新日志详情
	 * @returns
	 */
	getUpdateLogInfo( params ) {
		return http.get( `${rootUrl}/getDentalPushInfo`, { params, errCode: 'commonApi.getUpdateLog' });
	},
	// /**
	//  * 获取oem
	//  * @returns
	//  */
	// getOem( lang ) {
	// 	return http.get( `${rootUrl}/getOEMAreaInfoV2?oemURL=index&lang=${lang}`, { errCode: 'commonApi.getOem' });
	// },
	/**
	 * 获取国家码
	 * @returns
	 */
	getCountry() {
		return http.get( `${rootUrl}/fetchCountriesV2`, { errCode: 'commonApi.getCountry' });
	},
	/**
	 * 登录
	 * @param {*} data
	 * @returns
	 */
	login( data ) {
		return http.post( `${rootUrl}/loginV2`, { data, errCode: 'commonApi.login' });
	},
	/**
	 *
	 * @param {String} targetData
	 */
	getSettingList( targetData, query ) {
		return http.post( `${rootUrl}/resourceListV2`, { data: { nameCode: targetData }, errCode: 'commonApi.getSettingList' }, { params: query });
	},
	/**
	 *
	 * @param {String} targetData
	 */
	settingsLang( params ) {
		return http.get( `${rootUrl}/setUserLangV2?lang=${params.lang}`, { errCode: 'commonApi.settingsLang' });
	},
	/**
	 * 获取模型的标注记录
	 * @param {String} target
	 */
	getThreeDModelLabels( params ) {
		return http.get( `${rootUrl}/fetchAttachModelsMarker`, { params });
	},
	/**
	 * 新增模型的标注
	 * @param {*} data
	 * @returns
	 */
	addThreeDModelLabel( data ) {
		return http.post( `${rootUrl}/addAttachModelsMarker`, { data }, { headers: { isCsrf: true } });
	},
	/**
	 * 删除模型的标注
	 * @param {*} data
	 * @returns
	 */
	deleteThreeDModelLabel( data ) {
		return http.post( `${rootUrl}/delAttachModelsMarker`, { data }, { headers: { isCsrf: true } });
	},
	/**
	 * 编辑模型的标注
	 * @param {*} data
	 * @returns
	 */
	editThreeDModelLabel( data ) {
		return http.post( `${rootUrl}/updateAttachModelsMarker`, { data }, { headers: { isCsrf: true } });
	},
	/**
	 * 批量更新模型的标准倒凹值
	 * @param {*} data
	 * @returns
	 */
	batchUpdateThreeDModelLabel( data ) {
		return http.post( `${rootUrl}/batchUpdateAttachModelsMarker`, { data }, { headers: { isCsrf: true } });
	},
	/**
	 * 获取模型的标注数量
	 * @param {*} params
	 * @returns
	 */
	getThreeDModelLabelSumNum( data ) {
		return http.post( `${rootUrl}/getAttachModelsMarkerTotal`, { data }, { headers: { isCsrf: true } });
	},
	/**
	 * 触发模型转换
	 * @param {*} params
	 * @returns
	 */
	handleModelTranslate( params ) {
		return http.get( `${rootUrl}/orderTrigger`, { params });
	},
	/**
	 *
	 * @param {String} targetData
	 */
	getTempUrlList( params ) {
		return http.get( `${rootUrl}/getTempDownloadURL`, { params, errCode: 'commonApi.getTempUrlList' });
	},
	/**
   * 签到 得积分
   * @param {*} params
   * @returns
   */
	pointsSignIn( params ){
		return http.get( `${rootUrl}/signInUserPoints`, { params });
	},
	/**
 * 根据文件路径下载
 * 可以直接拼接也可以请求后创建a标签文件流下载
 * @param {
	*   name: string; // 下载文件名称
	*   url: string; // 下载文件路径
	* } params
	* @returns
	*/
	download( params ){
		return http.get( '/download', { params, errCode: 'commonApi.download' });
	},
	/**
	 * 发起模型的倒凹检测
	 * @returns detectID 任务ID，作为订阅倒凹检测结果的唯一键
	 */
	triggerUnderCutDetect( data ) {
		// todo: 倒凹接口的更新
		return http.post( `${rootUrl}/triggerUnderCutDetect`, { data }, { headers: { isCsrf: true } });
	},
	/**
	 * 发起模型的关键点检测
	 * @returns detectID 任务ID，作为订阅关键点检测结果的唯一键
	 */
	triggerDetectToothPoint( data ) {
		return http.post( `${rootUrl}/triggerDetectToothPoint`, { data }, { headers: { isCsrf: true } });
	},
	/**
	 * 后端登出
	 */
	userLogout( data ) {
		return http.post( '/api/userLogout', { data, errCode: 'commonApi.userLogout' });
	},
	/**
	 * 换取dentalx平台凭证
	 */
	getDentalxAuthToken( params ) {
		return http.get( '/api/getDentalxAuthToken', { params, errCode: 'commonApi.getDentalxAuthToken' });
	},
	/**
	 * 获取h5二维码宣传图
	 * @param {*} params
	 * @returns
	 */
	getQrCodeImage( params ) {
		return http.get( `${rootUrl}/fetchDentalStaticResource`, { params });
	},
	/**
	 * 获取订单信息list（加工日志，主材信息，发货信息等）
	 * @param {object} params
	 * @returns
	 */
	dentalD( params ) {
		return http.get( `${rootUrl}/detailOrderByIDTypeV2`, {
			params,
			errCode: 'cases.dentalD'
		});
	},
	/**
	 * 获取Oem Banner Icon
	 * @param {string} ID
	 * @returns
	 */
	oemInfoGetByID( params ) {
		return http.post( `${rootUrl}/dentalFactoryOEMListV2`, { data: params, errCode: 'InstitutionSetting.getOemInfo' });
	},
	/**
	 * 节点跳转
	 * @param {string} ID
	 * @returns
	 */
	frontNodeJumpRequestSignal( params ) {
		return http.post( `${rootUrl}/frontNodeJumpRequestSignal`, { data: params });
	}
};
export default commonApi;
