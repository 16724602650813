// mouse.js

import get from 'lodash/get';
import { reactive } from 'vue';
import replace from 'lodash/replace';
export function useLang( langname = '@shining3d/app' ) {
	let lang = reactive({});

	const langKey = `app.i18n.${langname}`;

	function $t( val, attr ) {
		let txt = get( window, `${langKey}.${val}`, val );
		if ( attr && txt ){
			Object.keys( attr ).forEach(( key ) => {
				attr[ key.toLowerCase() ] = attr[ key ];
			});
			txt.match( /{(.*?)}/g ) && txt.match( /{(.*?)}/g ).forEach(( key ) => {
				txt = replace( txt, key, attr[ key.toLowerCase().replace( /^{+|}+$/g, '' ) ]);
			});
		}
		return txt || '';
	}
	function $getLang( val ) {
		lang = Object.assign({}, window.app.i18n[ val ] || {});
	}

	// 获取语言包
	if ( langname ) {
		$getLang( langname );
	}

	// 通过返回值暴露所管理的状态
	return { lang, $t, $getLang };
}