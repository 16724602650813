import http from '@/utils/http';
import api from '@/api/common';
const isDev = process.env.NODE_ENV === 'development';
let rootUrl = '/api';
const dentalxBaseUrl = '/dentalx/api';

if ( isDev ) {
	rootUrl = '/api';
}
const Printer = {
	/**
	 * 获取订单信息list（加工日志，主材信息，发货信息等）
	 * @param {object} params
	 * @returns
	 */
	dentalD( params ) {
		return api.dentalD( params );
	},
	/**
	 * 订单列表-附带设计文件数据
	 * @param {object} params
	 * @returns
	 */
	dentalDesignOrderList( params ) {
		return http.get( `${rootUrl}/designOrderList`, { params, errCode: 'slice.dentalDesignOrderList' });
	},
	/**
	 * 删除导入文件
	 * @param {object} params
	 * @returns
	 */
	delFile( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpDelImportFile`, { data, errCode: 'slice.fileDel' }, { headers: { isCsrf: true } });
	},
	/**
	 * 齿科云平台-打印机-切片-导入文件
	 * @returns
	 */
	importFile( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpImportFile`, { data, errCode: 'slice.fileImport' }, { headers: { isCsrf: true } });
	},
	dlpCreateSliceTask( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpCreateSliceTask`, { data, errCode: 'slice.dlpCreateSliceTask' }, { headers: { isCsrf: true } });
	},
	/**
	 * 齿科云平台-打印机-切片-模型检测
	 * @returns
	 */
	checkModel( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpCheckModel`, { data, errCode: 'slice.modelCheck' }, { headers: { isCsrf: true } });
	},
	/**
	 * 文件页-模型列表
	 * @param {object} params
	 * @returns
	 */
	modelCheckList( params ) {
		return http.get( `${rootUrl}/dlpModelCheckList`, { params, errCode: 'slice.checkList' });
	},
	/**
	 * 齿科云平台-打印机-切片-模型自动修复
	 * @returns
	 */
	repairModel( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpGenerateGltf`, { data, errCode: 'slice.modelGenerateGltf' }, { headers: { isCsrf: true } });
	},
	/**
	 * 齿科云平台-打印机-切片-模型列表-文件页
	 * @returns
	 */
	modelList( params ) {
		return http.get( `${rootUrl}/dlpModelList`, { params, errCode: 'slice.modelList' });
	},
	/**
	 * 齿科云平台-打印机-切片-模型自动布局
	 * @returns
	 */
	autoLayoutModel( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpAutoLayoutModel`, { data, errCode: 'slice.modelLayout' }, { headers: { isCsrf: true } });
	},
	/**
	 * 用户手动调整模型后重新生成支撑
	 * @returns
	 */
	autoSupportModel( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpAutoSupportModel`, { data, errCode: 'slice.modelSupport' }, { headers: { isCsrf: true } });
	},
	/**
	 * 模型列表-布局与支撑页
	 * @returns
	 */
	supportModelList( params ) {
		return http.get( `${rootUrl}/dlpSupportModelList`, { params, errCode: 'slice.supportList' });
	},
	/**
	 * 用户手动修改支撑
	 * @returns
	 */
	supportModelByUser( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpSupportModelByUser`, { data, errCode: 'slice.supportCustom' }, { headers: { isCsrf: true } });
	},
	/**
	 * 切片
	 * @returns
	 */
	sliceModel( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpSliceModel`, { data, errCode: 'slice.modelSlice' }, { headers: { isCsrf: true } });
	},
	/**
	 * 切片下载地址列表
	 * @returns
	 */
	sliceList( params ) {
		return http.get( `${rootUrl}/dlpSliceList`, { params, errCode: 'slice.sliceList' });
	},
	/**
	 * 云端切片计算打印耗时
	 * @returns
	 */
	calculateWorkDuration( data ) {
		return http.postWithErrMessage( `${rootUrl}/calculateWorkDuration`, { data, errCode: 'slice.calculateWorkDuration' }, { headers: { isCsrf: true } });
	},
	/**
	 * 查询当前任务状态
	 * @returns
	 */
	sliceTaskStatus( params ) {
		return http.get( `${rootUrl}/sliceTaskStatus`, { params, errCode: 'slice.sliceTaskStatus' });
	},
	/**
	 * dlp软件端基础信息列表接口
	 * @returns
	 */
	dlpBaseInfoUList( params ) {
		return http.get( `${rootUrl}/dlpBaseInfoUList`, { params, errCode: 'slice.dlpBaseInfoUList' });
	},
	/**
	 * 应用设置列表
	 * @returns
	 */
	appSettingList( params ) {
		return http.get( `${rootUrl}/dlpAppSettingList`, { params, errCode: 'slice.settingFetch' });
	},
	/**
	 * 增加或修改应用设置
	 * @param {*} data
	 * @returns
	 */
	saveAppSetting( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpSaveAppSettiong`, { data, errCode: 'slice.settingSave' }, { headers: { isCsrf: true } });
	},
	/**
	 * 查看更多设置
	 * @returns
	 */
	getMoreSetting( params ) {
		return http.get( `${rootUrl}/dlpGetMoreSettiong`, { params, errCode: 'slice.settingGet' });
	},
	/**
	 * 增加或修改更多设置
	 * @param
	 * @returns
	 */
	saveMoreSetting( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpSaveMoreSettiong`, { data, errCode: 'slice.settingSave' }, { headers: { isCsrf: true } });
	},
	/**
	 * 删除应用设置
	 */
	deleteAppSetting( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpDeleteAppSetting`, { data, errCode: 'slice.settingDel' }, { headers: { isCsrf: true } });
	},
	/**
	 * 唤起accuware
	 */
	generateSecret( data ) {
		return http.postWithErrMessage( `${rootUrl}/generateFileDownloadSecret`, { data, errCode: 'slice.fileGenerateSecret' }, { headers: { isCsrf: true } });
	},
	changeSetting( data ) {
		return http.postWithErrMessage( `${rootUrl}/changeSliceSetting`, { data, errCode: 'slice.changeSetting' }, { headers: { isCsrf: true } });
	},
	compareTaskSetting( data ) {
		return http.postWithErrMessage( `${rootUrl}/compareTaskSetting`, { data, errCode: 'slice.changeSetting' }, { headers: { isCsrf: true } });
	},
	dlpSliceCheck( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpSliceCheck`, { data, errCode: 'slice.dlpSliceCheck' }, { headers: { isCsrf: true } });
	},
	slicePower( data ) {
		return http.postWithErrMessage( `${rootUrl}/dlpSlicePower`, { data, errCode: 'slice.slicePower' }, { headers: { isCsrf: true } });
	},
	/**
	 * 可打印设计单列表
	 * @param {object} params
	 * @returns
	 */
	dentalxOrderPrintList( params ) {
		return http.get( `${dentalxBaseUrl}/dentalxOrderPrintList`, { params, errCode: 'slice.dentalxOrderPrintList' });
	}
};
export default Printer;
