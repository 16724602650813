import http from '@/utils/http';
import Api from '@/api/common';
const rootUrl = '/api';

const InstitutionSetting = {
	/**
	 * 获取机构信息 factoryType: lab,hospital
	 * @param {tid} params
	 * @returns
	 */
	getInfo( params ) {
		return http.get( `${rootUrl}/dentalFactoryDetail`, { params, errCode: 'InstitutionSetting.getInfo' });
	},
	/**
	 * 修改机构信息
	 * @returns
	 */
	edit( data ) {
		return http.post( `${rootUrl}/dentalFactorySettingV2`, { data, errCode: 'InstitutionSetting.edit' });
	},
	/**
	 * 修改OEM信息
	 * @returns
	 */
	oemEdit( data ) {
		return http.post( `${rootUrl}/dentalFactoryOEMBatchSettingV2`, { data, errCode: 'InstitutionSetting.oemEdit' }, { headers: { isCsrf: true } });
	},
	/**
 * 获取OEM信息
 * @returns
 */
	getOemInfo( params ) {
		return Api.oemInfoGetByID({ tid: params.id, source: window.app.entryApiName });
	},
	/**
	 * 删除OEM信息
	 * @param {
		* "orgID": "", 机构id
		* "oemIDs": "", oem Id list
		* "lang": "", 语言
		* "source": "", 固定类型
		* "validCode": "", 验证码
		* "account": "", 账号
		* "phoneArea": "" 国家码
		* } config
	* @returns
 */
	delOemInfo( data ) {
		return http.post( `${rootUrl}/dentalFactoryOEMBatchDelete`, { data, errCode: 'InstitutionSetting.delOemInfo' }, { headers: { isCsrf: true } });
	},
	/**
   * 齿科机构成员职称列表接口
   * @param {*} params 
   * @returns 
   */
	dentalTitleList( params ) {
		return http.get( `${rootUrl}/dentalTitleList`, { params });
	},
	/**
   * 获取已编辑的个数
   * @param {*} params 
   * @returns 
   */
	dentalTitleGetUseMemberNum( params ) {
		return http.get( `${rootUrl}/dentalTitleGetUseMemberNum`, { params });
	},
	/**
   * 齿科机构成员职称编辑接口
   * @param {*} data 
   * @returns 
   */
	dentalTitleEdit( data ) {
		return http.post( `${rootUrl}/dentalTitleEdit`, { data, errCode: 'InstitutionSetting.dentalTitleEdit' });
	},
	/**
   * 批量添加机构职称配置
   * @param {*} data 
   * @returns 
   */
	dentalTitleBatchInsert( data ) {
		return http.post( `${rootUrl}/dentalTitleBatchInsert`, { data, errCode: 'InstitutionSetting.dentalTitleBatchInsert' });
	},
	/**
   * 获取患者标签列表
   * @param {*} params 
   * @returns 
   */
	 dentalPatientTagList( params ) {
		return http.get( `${rootUrl}/dentalPatientTagList`, { params });
	},
	/**
   * 添加患者标签配置
   * @param {*} data 
   * @returns 
   */
	 dentalPatientTagAdd( data ) {
		return http.post( `${rootUrl}/dentalPatientTagAdd`, { data, errCode: 'InstitutionSetting.dentalTitleBatchInsert' });
	},
	/**
   * 编辑患者标签配置
   * @param {*} data 
   * @returns 
   */
	 dentalPatientTagConfigEdit( data ) {
		return http.post( `${rootUrl}/dentalPatientTagConfigEdit`, { data, errCode: 'InstitutionSetting.dentalTitleBatchInsert' });
	},
	/**
   * 编辑患者标签
   * @param {*} data 
   * @returns 
   */
	 dentalPatientEditTag( data ) {
		return http.post( `${rootUrl}/dentalPatientEditTag`, { data, errCode: 'InstitutionSetting.dentalTitleBatchInsert' });
	},
	/**
   * 获取标签个数
   * @param {*} params 
   * @returns 
   */
	dentalPatientTagGetUsages( params ) {
		return http.get( `${rootUrl}/dentalPatientTagGetUsages`, { params });
	}
	
};
export default InstitutionSetting;
