import { isNumber } from 'lodash';
function addZero( number ) {
	if ( number < 10 ) {
		return `0${number}`;
	} else {
		return number;
	}
}
export function formatDuration( seconds ) {
	const hours = Math.trunc( seconds / 3600 );
	const minutes = Math.trunc(( seconds % 3600 ) / 60 );
	const remainingSeconds = seconds % 60;
	return `${String( hours ).padStart( 2, '0' )}:${String( minutes ).padStart( 2, '0' )}:${String( remainingSeconds ).padStart( 2, '0' )}`;
}
export function getTimeBySecond( second, type = 'auto', format = 'hh:mm' ) {
	let _second = 0;
	if ( !isNumber( second ) && isNaN( Number( second ))) {
		throw new Error( '参数必须是数字或可转为数字的字符串' );
	} else {
		_second = Number( second );
	}
	_second = second;
	let h = 0;
	let _h = 0;
	let m = 0;
	const flag = format === 'hh:mm';
	h = Math.trunc( _second / 3600 );
	_h = _second % 3600;
	m = Math.trunc( _h / 60 );
	if ( type === 'auto' ) {
		if ( h > 0 && m > 0 ) {
			return flag ? `${addZero( h )}:${addZero( m )}` : `${addZero( h )}h${addZero( m )}m`;
		} else if ( h && m === 0 ) {
			return flag ? `${addZero( h )}:00` : `${addZero( h )}h`;
		} else if ( h === 0 && m >= 0 ) {
			return flag ? `${addZero( h )}:${addZero( m )}` : `${h}h${addZero( m )}m`;
		} else {
			return flag ? '00:00' : '0h0m';
		}
	} else if ( type === 'h' ) {
		return flag ? `${addZero( h )}:00` : `${h}h`;
	} else {
		return flag ? `${addZero( h )}:${addZero( m )}` : `${h}h${addZero( m )}m`;
	}
}
