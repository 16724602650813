import { VueCookieNext } from 'vue-cookie-next';
import { $rdoLang } from '@/assets/common/common.js';
const rootUrl = '/api';
const data = {
	oemURL: VueCookieNext.getCookie( 'oemURL' ),
	lang: $rdoLang( VueCookieNext.getCookie( 'lang' ))
};
let apiWhiteErrList = [];

// oem变换更新
function updatedOem() {
	data.oemURL = VueCookieNext.getCookie( 'oemURL' );
	apiWhiteErrList = [
		{ url: `${rootUrl}/signInUserPoints`, err: ['duplicate'] }
	];
}
updatedOem();
export { apiWhiteErrList, updatedOem };
