import { useLang } from '@/hooks/lang';
import { get as _get } from 'lodash';
const { $t } = useLang( '@shining3d/app' );
export default [
	{
		path: '/info',
		name: 'info',
		component: () => import( '@shining3d/info-component/index.vue' ),
		meta: {
			sideList: [
				{ path: '/info/personalInfo', name: $t( 'personalInformation.side.info' ), icon: 'icon-gerenzhongxin' },
				{ path: '/info/accountPassword', name: $t( 'personalInformation.side.password' ), icon: 'icon-zhanghaomima' },
				{ path: '/info/preference', name: $t( 'personalInformation.side.preference' ), icon: 'icon-shezhi-xianxing' },
				{ path: '/info/mechanism', name: $t( 'personalInformation.side.mechanism' ), icon: 'icon-qiyemingcheng' }
			]
		},
		children: [
			{
				path: '/info/personalInfo',
				name: 'personalInfo',
				component: () => import( '@shining3d/info-component/infoList/personal-info.vue' ),
				// 是否展示邮件营销
				meta: { isMarketMailCheck: !_get( window, 'app.global.isDisabledMarketMailSubscribed', false ) }
			},
			{
				path: '/info/accountPassword',
				name: 'accountPassword',
				component: () => import( '@shining3d/info-component/infoList/account-password.vue' ),
				// 是否展示邮件营销
				meta: { isMarketMailCheck: !_get( window, 'app.global.isDisabledMarketMailSubscribed', false ) }
			},
			{
				path: '/info/preference',
				name: 'preference',
				component: () => import( '@shining3d/info-component/infoList/preference.vue' )
			},
			{
				path: '/info/mechanism',
				name: 'mechanism',
				component: () => import( '@shining3d/info-component/infoList/mechanism.vue' )
			}
		],
		redirect: '/info/personalInfo'
	}
];
