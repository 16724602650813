export default [
	{
		path: '/reportNew',
		name: 'reportNew',
		component: () => import( /* webpackChunkName: 'report' */ './index.vue' )
	},
	{
		path: '/report/v2',
		name: 'oralReport',
		component: () => import( /* webpackChunkName: 'report' */ './oralReport/index.vue' )
	},
	{
		path: '/reports',
		name: 'dataAudit',
		component: () => import( './dataAudit/index.vue' )
	}
];
