import http from '@/utils/http';
const rootUrl = '/api';

const Login = {
	/**
	 * 获取服务器时间
	 * @returns
	 */
	getIpc() {
		return http.get( `${rootUrl}/ipCountry` );
	},
	/**
	 * 获取联系方式
	 * @returns
	 */
	getOiga( lang, oemUrl ) {
		return http.get( `${rootUrl}/getOEMAreaInfoV2?oemURL=${oemUrl}&lang=${lang}&origin=${window.app.entryApiName}`, { errCode: 'login.getOiga' });
	},
	/**
	 * 获取首页banner
	 */
	getOig( oemUrl ) {
		return http.get( `${rootUrl}/getOEMInfoV2?oemURL=${oemUrl}`, { errCode: 'login.getOig' });
	},
	/**
	 * 获取隐私政策
	 */
	getOigpc( lang, oemUrl, type ) {
		return http.get( `${rootUrl}/getOEMPrivacyColorInfoByTypeV2?oemURL=${oemUrl}&lang=${lang}&origin=${window.app.entryApiName}&privacyType=${type}`, { errCode: 'login.getPrivacy' });
	},
	/**
	 * 授权登陆参数检查
	 * @param {*} config
	 * @returns
	 */
	getCheck( config ) {
		return http.getConfig( `${rootUrl}/oauth2Check`, config );
	},
	/**
	 * /other/u/oauth2/code
	 */
	gotoCode( config ) {
		return http.getConfig( `${rootUrl}/oauth2GetCode`, config );
	},
	/**
	 * 完善信息接口
	 * @param {*} params
	 * @returns
	 */
	accountImprove( data ) {
		return http.post( `${rootUrl}/improveAccountv2`, { data, errCode: 'login.accountImprove' });
	},
	/**
   * 
   * @param {*} oemUrl 
   * @returns 
   */
	existOem( params ) {
		return http.getConfig( `${rootUrl}/domainExist`, { params });
	},
	/**
   * 获取所有节点列表
   * @param {*} oemUrl 
   * @returns 
   */
	nodeList() {
		return http.get( `${rootUrl}/getNodeList?productline=dentalCloud` );
	}
};
export default Login;
