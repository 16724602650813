import http from '@/utils/http';

const rootUrl = '/api';

const Info = {
	/**
	 * 获取个人信息
	 * @returns
	 */
	getInfo() {
		return http.get( `${rootUrl}/getUserProfile`, { errCode: 'info.getInfo' });
	},
	/**
	 * 设置个人信息
	 * @returns
	 */
	setInfo( data ) {
		return http.post( `${rootUrl}/setUserProfileV2`, { data, errCode: 'info.setInfo' });
	},
	/**
	 * 修改头像
	 * @returns
	 */
	setAvatar( data ) {
		return http.post( `${rootUrl}/setUserAvatarV2`, { data, errCode: 'info.setAvatar' });
	},
	/**
	 * 发送验证码
	 * @param {*} data
	 * @returns
	 */
	userValidate( data ) {
		return http.post( `${rootUrl}/userInfoValidateV2`, { data, errCode: 'info.userValidate' }, { headers: { 'X-Auth-Token': data.captcha, isCsrf: true } });
	},
	userValidateV2( data ) {
		return http.post( `${rootUrl}/userInfoValidateV2`, { data, errCode: 'info.userValidateV2' }, { headers: { 'X-Auth-Token': data.captcha, isCsrf: true } });
	},
	/**
	 * 获取禁用域名列表
	 * @param {*} data
	 * @returns
	 */
	getDisableDomainList() {
		return http.get( `${rootUrl}/disableDomainV2`, { errCode: 'apiErr.info.disableDomainErr' });
	},
	/**
	 * 修改手机邮箱绑定
	 * @param {
	 * "uuid": "", 用户id
	 * "captcha": "", 图形码
	 * "lang": "", 语言
	 * "source": "", 固定类型
	 * "validCode": "", 验证码
	 * "account": "", 账号
	 * "phoneArea": "" 国家码
	 * } config
	 * @returns
	 */
	bindSilde( data ) {
		return http.post( `${rootUrl}/userInfoBindWithSlideV2`, { data, errCode: 'info.bindSilde' });
	},
	/**
	 * 手机邮箱解绑
	 * userID 用户id
	 * target 类型 phone / email
	 * @returns
	 */
	unBindSilde( userID, target ) {
		return http.get( `${rootUrl}/userUnbindV2?userID=${userID}&target=${target}`, { errCode: 'info.unBindSilde' });
	},
	/**
	 * 第三方绑定查询
	 * loginType 类型 wechat
	 * @returns
	 */
	getBindOauth( type ) {
		return http.get( `${rootUrl}/queryOauthBindInfoApi?loginType=${type}&platform=${window.app.entryApiName}`, { errCode: 'info.getBindOauth' });
	},
	/**
	 * 第三方解绑
	 * loginType 第三方类型
	 * openId 第三方绑定id
	 * @param {"loginType": "wechat","openId": "xxxx"} data
	 * @returns
	 */
	unBindOauth( data ) {
		return http.post( `${rootUrl}/socialMediaUnbindApiV2`, { data, errCode: 'info.unBindOauth' });
	},
	/**
	 * 以旧密码修改密码接口
	 * @param {"uID": "","password": "","newpassword": ""} config
	 * @returns
	 */
	cpoPassWord( data ) {
		return http.post( `${rootUrl}/changePwdV2`, { data, errCode: 'info.cpoPassWord' });
	},
	/**
	 * 获取通知提醒配置
	 * @returns
	 */
	getNotice() {
		return http.get( `${rootUrl}/fetchUserNoticeV2?source=${window.app.entryApiName}`, { errCode: 'info.getNotice' });
	},
	/**
	 * 修改提醒通知单个/多个
	 * @returns
	 */
	setNotice( data ) {
		return http.post( `${rootUrl}/setUserNoticesV2`, { data, errCode: 'info.setNotice' });
	},
	/**
	 * 设置用户下载即默认接单
	 * @returns
	 */
	setDefaultOrderReceive( isDefault ) {
		return http.get( `${rootUrl}/setUserDefaultOrderReceive?receive=${isDefault}`, { errCode: 'info.setDefaultOrderReceive' });
	},
	/**
	 * 当前账户所属机构列表接口
	 * @returns
	 */
	getFactoryList( params ) {
		return http.get( `${rootUrl}/dentalMemberAffiliatedOrgs`, { params, errCode: 'info.getFactoryList' });
	},
	/**
	 * 当前账户权限点
	 * @returns
	 */
	getPermList( params ) {
		return http.get( `${rootUrl}/getUserPerm`, { params, errCode: 'info.getPermList' });
	},
	/**
	 * 齿科机构成员行为处理接口
	 * @returns
	 */
	memberAction( data ) {
		return http.post( `${rootUrl}/actionDentalFactoryMemberV2`, { data, errCode: 'info.memberAction' });
	},
	/**
	 * 获取用户注销信息
	 */
	cancelInfo( params ) {
		return http.get( `${rootUrl}/userCancelInfo`, { params, errCode: 'info.cancelInfo' });
	},
	/**
	 * 用户注销申请
	 */
	cancelApply( data ) {
		return http.post( `${rootUrl}/applyCancelUserV2`, { data, errCode: 'info.bindSilde' });
	},
	/**
	 * 用户撤回注销申请
	 */
	cancelRevoke( params ) {
		return http.get( `${rootUrl}/revokeCancelUser`, { params, errCode: 'info.bindSilde' });
	},
	/**
	 * 判断用户是否是唯一管理员
	 */
	factoryOnlyAdmin( params ) {
		return http.get( `${rootUrl}/userOnlyAdminFactories`, { params, errCode: 'info.bindSilde' });
	},
	/**
	 * 用户注销申请短信校验
	 */
	cancelCheck( data ) {
		return http.post( `${rootUrl}/checkCancelUserV2`, { data, errCode: 'info.bindSilde' });
	},
	/**
	 * 当前机构已使用的空间大小
	 */
	getOrgAttachSpaceSize( params ) {
		return http.get( `${rootUrl}/getOrgAttachSpaceSize`, { params, errCode: 'coldArchive' });
	}
};
export default Info;
