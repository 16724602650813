import { getColorList, getDeviceColor } from './getDeviceColor';
import getOrgID from './getOrgID';
import { getTimeBySecond } from './getTime';
import randomColor from './randomColor';
import generateUUID from './generateUUID';
import getUserInfo from './getUserInfo';
import { XlMessage } from '@shining3d/ui-components';

const unitMatrix = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];

const getMatrixByStr = ( str ) => {
	if ( !str ) {
		return unitMatrix;
	}
	return str.split( ' ' ).map(( item ) => Number( item ));
};

const convertMatrix = ( m ) => {
	if ( !( Array.isArray( m ) && m.length === 16 && m.every(( item ) => typeof item === 'number' ))) {
		return unitMatrix;
	}
	return m;
};
/**
 * 下载文件
 * @param {String} url 文件下载地址
 * @param {String} name 文件名
 * @returns
 */

// const downloadFile = ( url, fileName ) => fetch( url ).then(( res ) => {
// if ( res.status === 200 ) {
// return res.blob().then(( blob ) => {
// const blobURL = window.URL.createObjectURL( blob );
// const a = document.createElement( 'a' );
// a.href = blobURL;
// a.download = fileName;
// a.click();
// window.URL.revokeObjectURL( blobURL );
// });
// } else {
// throw new Error();
// }
// });
const downloadFile = ( url, name ) => {
	if ( !url ) {
		return;
	}
	const link = document.createElement( 'a' );
	if ( name ) {
		link.download = name;
	}
	link.style.display = 'none';
	link.href = url;
	document.body.appendChild( link );
	link.click();
	document.body.removeChild( link );
};

// 有些需求是要在某个页面上才提示，遇到请求回调里面提示这种情况就不行，接口慢，页面已经切菜单了，再提示，有些违和
const messageWithDomFun = ( selector ) => ( messageOptions ) => {
	const dom = document.querySelector( selector );
	if ( dom ) {
		XlMessage( messageOptions );
	}
};

export { getDeviceColor, getOrgID, messageWithDomFun, downloadFile, convertMatrix, getUserInfo, getTimeBySecond, randomColor, getColorList, generateUUID, getMatrixByStr };
