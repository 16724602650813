import http from '@/utils/http';
const rootUrl = '/api';

const scanOrders = {
	/**
	 * 获取扫描单列表
	 * @param {*} params
	 * @returns
	 */
	getScanOrderList( params ) {
		return http.get( `${rootUrl}/scanReceiptList`, { params });
	},
	/**
	 * 创建扫描单
	 * @param {*} data
	 * @returns
	 */
	addScanOrder( data ) {
		return http.post( `${rootUrl}/addScanReceipt`, {
			data,
			errCode: 'scanOrder'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 变更扫描状态
	 * @param {*} data
	 * @returns
	 */
	changeScanOrderStatus( data ) {
		return http.post( `${rootUrl}/scanReceiptAction`, {
			data,
			errCode: 'scanOrder'
		}, { headers: { isCsrf: true } });
	},
	/**
	 * 获取扫描单详情
	 * @param {*} params
	 * @returns
	 */
	getScanOrderDetail( params ) {
		return http.get( `${rootUrl}/scanReceiptDetail`, { params });
	},
	/**
	 * 获取扫描单模型数据
	 * @param {*} params
	 * @returns
	 */
	getScanOrderModelData( params ) {
		return http.get( `${rootUrl}/scanReceiptPreviewFiles`, { params });
	},
	/**
	 * 获取扫描单下载列表
	 * @param {*} params
	 * @returns
	 */
	getScanOrderModelDownloadData( params ) {
		return http.get( `${rootUrl}/scanReceiptDownloadList`, { params });
	},
	/**
	 * 下载
	 * @param {*} data
	 * @returns
	 */
	receiptDownload( data ) {
		return http.post( `${rootUrl}/scanReceiptDownload`, { data, errCode: 'scanOrder' }, { headers: { isCsrf: true } });
	},
	/**
	 * 上传扫描单数据
	 * @param {*} data
	 * @returns
	 */
	// uploadModelData( data ) {
	// 	return http.post( `${rootUrl}/uploadScanReceiptAttach`, { data, errCode: 'scanOrder' }, { headers: { isCsrf: true } });
	// },
	/**
	 * 触发转换-扫描单下载
	 * @param {*} data
	 * @returns
	 */
	fileConversion( data ) {
		return http.post( `${rootUrl}/scanTriggerFileConversion`, { data, errCode: 'scanOrder' }, { headers: { isCsrf: true } });
	},
	/**
	 * 转换其他格式-扫描单下载
	 * @param {*} data
	 * @returns
	 */
	fileFormat( data ) {
		return http.post( `${rootUrl}/scanTriggerFileFormatConversion`, { data, errCode: 'scanOrder' }, { headers: { isCsrf: true } });
	},
	/**
	 * 批量解冻、转换归档文件
	 * @param {*} data
	 * @returns
	 */
	batchRestoreAttach( data ) {
		return http.post( `${rootUrl}/batchRestoreAttach`, {
			data,
			errCode: 'coldArchive'
		}, { headers: { isCsrf: true } });
	}
	// scanReceiptList( data ) {
	// 	return http.post( `${rootUrl}/scanReceiptListByPatient`, { data, errCode: 'scanReceiptList' }, { headers: { isCsrf: true } });
	// }
};
export default scanOrders;
